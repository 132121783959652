var __jsx = React.createElement;
import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import Anchor from '../../atoms/Anchor';
import List from '../List/List';
import ListItem from '../List/ListItem';
import ArrowDown from '../../../static/images/icons/ArrowDown';
import analyticsConstants from '../../../constants/analyticsConstants';
import Dropdown from '../Dropdown';
var headerAnalytics = analyticsConstants.globalHeader;
var NavGroupItem = function NavGroupItem(_ref) {
  var _navItem$groupCollect;
  var navItem = _ref.navItem,
    openDropdownOnClick = _ref.openDropdownOnClick,
    menuType = _ref.menuType,
    index = _ref.index;
  var classes = {
    wrapper: 'nva-nav-list__group',
    button: 'nva-nav-list__item nva-nav-list__item--group',
    dropdown: 'nva-nav-list__submenu'
  };
  var buttonStyleType;
  if (menuType === 'cta') {
    buttonStyleType = index === 0 ? 'primary-anchor' : 'secondary-anchor';
  }
  return __jsx(Dropdown, {
    classes: classes,
    label: navItem.keyCategoryName,
    buttonStyleType: buttonStyleType,
    icon: navItem.icon,
    arrowIcon: navItem.arrowIcon,
    type: menuType,
    openDropdownOnClick: openDropdownOnClick,
    analyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
    analyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_CATEGORY_MENU
  }, __jsx(List, null, (_navItem$groupCollect = navItem.groupCollection.items) === null || _navItem$groupCollect === void 0 ? void 0 : _navItem$groupCollect.map(function (groupItems) {
    var _groupItems$groupItem;
    var submenuItems = (_groupItems$groupItem = groupItems.groupItemCollection) === null || _groupItems$groupItem === void 0 ? void 0 : _groupItems$groupItem.items;
    return submenuItems === null || submenuItems === void 0 ? void 0 : submenuItems.map(function (item) {
      return __jsx(Fragment, {
        key: item.sys.id
      }, item.__typename === 'ItemLabel' ? __jsx(ListItem, {
        className: "nva-nav-list__submenu-label"
      }, item.itemName) :
      // __typename Link
      __jsx(ListItem, {
        className: "nva-nav-list__submenu-item"
      }, __jsx(Anchor, {
        title: item.label,
        "aria-label": item.ariaLabel,
        className: "nva-nav-list__submenu-link",
        to: item.url,
        ctaBehavior: item.behavior,
        hyperlinkType: item.hyperlinkType,
        styleType: "secondary-link",
        "data-analytics-type": "sub-menu"
      }, item.label)));
    });
  })));
};
NavGroupItem.prototype = {
  navItem: PropTypes.object,
  openDropdownOnClick: PropTypes.bool
};
export default NavGroupItem;