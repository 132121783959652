var __jsx = React.createElement;
import React from 'react';
import Anchor from '../../atoms/Anchor';
import analyticsConstants from '../../../constants/analyticsConstants';
var globalHeader = analyticsConstants.globalHeader,
  globalFooter = analyticsConstants.globalFooter;
var analyticsMap = {
  header: globalHeader,
  footer: globalFooter
};
var NavItem = function NavItem(_ref) {
  var item = _ref.item,
    menuType = _ref.menuType,
    parentType = _ref.parentType,
    index = _ref.index;
  var analytics = analyticsMap[parentType];
  console.log("ITEM", item);
  var styleType;
  var analyticsType = analytics.analyticsType.GLOBAL_NAV;
  if (menuType === 'cta') {
    styleType = index === 0 ? 'primary-anchor' : 'secondary-anchor';
    analyticsType = index === 0 && parentType === 'footer' ? analytics.analyticsType.GLOBAL_NAV_FOOTER_PRIMARY : analytics.analyticsType.GLOBAL_NAV;
  }
  return __jsx(Anchor, {
    className: "nva-nav-list__item",
    styleType: styleType,
    title: item.label,
    "aria-label": item.ariaLabel,
    to: item.url,
    hyperlinkType: item.hyperlinkType,
    ctaBehavior: item.behavior,
    "data-analytics-type": analyticsType,
    "data-analytics-value": (item === null || item === void 0 ? void 0 : item.dataAnalyticsValue) || analytics.analyticsValue.GLOBAL_NAV_CATEGORY_LINK,
    icon: item.icon,
    iconPosition: item.iconPosition
  }, item.label);
};
NavItem.defaultProps = {
  menuType: 'link',
  parentType: 'header'
};
export default NavItem;